import { ref, watch } from 'vue'
import { shuffle } from 'lodash'
import useUtils from '@/composables/useUtils'
import useTracker from '../../tracker/composables/useTracker'

interface Cards {
  visible: boolean
  value: number
  name: string
  color: string
}

interface Info {
  moves: number
  matches: number
  errors: number
  status: boolean
  win: boolean
}
const stringfy = (data: Cards[]) => {
  return JSON.parse(JSON.stringify(data))
}
const info = ref<Info>({
  moves: 0,
  matches: 0,
  errors: 0,
  status: false,
  win: false
})

const useGameMemory = (countdown: number, items: Cards[]) => {
  const { elapsedFullTime, handleStart, handleStop, handlePause } = useTracker()
  const { delay } = useUtils()
  const cards = ref<Cards[]>([])
  const step = ref<1 | 2 | 3>()
  const cardOne = ref<number | false>()
  // const gameStatus = ref()

  const configs = {
    shuffleTime: 0.25 * 1000,
    revealTime: countdown * 1000
  }

  watch(
    () => info.value.matches,
    (newValue) => {
      if (newValue === items.length) {
        info.value.win = true
        handlePause()
        alert('UHU')
      }
    }
  )

  const resetInfo = () => {
    info.value = {
      moves: 0,
      errors: 0,
      matches: 0,
      status: true,
      win: false
    }
  }

  const startGame = async () => {
    step.value = 1
    // gameStatus.value = true
    resetInfo()
    handleStop()
    cardOne.value = false
    const randomCards = shuffle([...stringfy(items), ...stringfy(items)])
    cards.value = []
    for (let index = 0; index < randomCards.length; index++) {
      cards.value.push(randomCards[index])
      await delay(configs.shuffleTime)
    }
    await delay(configs.revealTime)
    cards.value.forEach((item) => {
      item.visible = false
    })
    handleStart()
  }

  const stepOne = (data: number) => {
    cards.value[data].visible = true
    cardOne.value = data
    step.value = 2
  }
  const stepTwo = async (data: number) => {
    cards.value[data].visible = true
    step.value = 3

    if (
      cards.value[cardOne.value as number].value === cards.value[data].value
    ) {
      info.value.matches++
      console.log('match')
      step.value = 1
    } else {
      // const { delay } = useUtils()
      await delay(500)
      info.value.errors++
      cards.value[cardOne.value as number].visible = false
      cards.value[data].visible = false
      step.value = 1
      cardOne.value = 0
    }
    info.value.moves++
  }

  const chooseCard = (data: number) => {
    if (step.value === 1) {
      stepOne(data)
    } else {
      if (step.value === 2) {
        stepTwo(data)
      }
    }
  }

  return { chooseCard, startGame, info, configs, cards, step, elapsedFullTime }
}

export default useGameMemory
