import { ref } from 'vue'
import { onAuthStateChanged, auth } from '@/plugins/firebase'
const user = ref(auth.currentUser)

onAuthStateChanged(auth, (_user) => {
  if (_user) {
    user.value = _user
  } else {
    user.value = null
  }
})

const getUser = () => {
  return { user }
}

export default getUser
