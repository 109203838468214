import { RouteRecordRaw } from 'vue-router'
import AboutView from './views/AboutView.vue'
import HomeView from './views/HomeView.vue'
import NotFoundView from './views/NotFoundView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'Home',
      requiresAuth: true,
      showInDrawer: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: {
      title: 'About',
      requiresAuth: false,
      showInDrawer: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundView,
    meta: {
      title: '404',
      requiresAuth: false
    }
  }
]

export default routes
