import { defineStore } from 'pinia'

export const useStore = defineStore('loading', {
  state: () => ({
    overlayLoading: false
  }),
  actions: {
    setOverlayLoading(data: boolean) {
      this.overlayLoading = data
    }
  }
})
