import { RouteRecordRaw } from 'vue-router'
import Counter from './views/CounterHomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/counter',
    name: 'Counter',
    component: Counter,
    meta: {
      requiresAuth: true,
      title: 'Counter',
      showInDrawer: true
    }
  }
]

export default routes
