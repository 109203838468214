import router from '@/router'

const useUtils = (): {
  filteredRoutes: typeof filteredRoutes
  uuid: typeof uuid
  delay: typeof delay
} => {
  const filteredRoutes = (filter: string) => {
    const routes = router.options.routes
    const filteredRoutes = routes.filter(
      (item) => item.meta && item.meta[filter] === true
    )
    const data = filteredRoutes.map((item) => {
      const title = item.meta ? item.meta.title : ''
      const icon = item.meta ? item.meta.icon : ''
      return {
        uid: uuid(),
        path: item.path,
        name: item.name,
        title,
        icon
      }
    })
    return data
  }

  const uuid = (): string => {
    const head = Date.now().toString(36).substring(0, 8)
    const body = Math.random().toString(36).substring(2, 6)
    const tail = Math.random().toString(36).substring(2, 10)
    return `${head}${body}${tail}`
  }

  const delay = (amount = 2000, msg = false): Promise<void> => {
    if (msg) {
      console.log(`Delay de ${amount / 1000} segundos para testes!`)
    }
    return new Promise((resolve) => setTimeout(resolve, amount))
  }

  return { filteredRoutes, uuid, delay }
}

export default useUtils
