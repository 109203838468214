import { RouteRecordRaw } from 'vue-router'
import Memory from './views/MemoryHomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/memory',
    name: 'Memory',
    component: Memory,
    meta: {
      requiresAuth: true,
      title: 'Jogo da Memória',
      showInDrawer: true
    }
  }
]

export default routes
