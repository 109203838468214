import { RouteRecordRaw } from 'vue-router'
import TrackerHomeView from './views/TrackerHomeView.vue'
import StopwatchView from './views/StopwatchView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/tracker',
    name: 'Tracker',
    component: TrackerHomeView,
    meta: {
      requiresAuth: false,
      title: 'Tracker',
      showInDrawer: true
    }
  },
  {
    path: '/stopwatch',
    name: 'Stopwatch',
    component: StopwatchView,
    meta: {
      requiresAuth: false,
      title: 'Stopwatch',
      showInDrawer: true
    }
  }
]

export default routes
