import { createApp, RendererElement } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { onAuthStateChanged, auth } from '@/plugins/firebase'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
loadFonts()

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  './components/app',
  false,
  /App[A-Z]\w+\.(vue|js)$/
)

let app: RendererElement

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)
    // eslint-disable-next-line
      requireComponent.keys().forEach((fileName: any) => {
      const componentConfig = requireComponent(fileName)
      const componentName = upperFirst(
        camelCase(
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      )

      app.component(componentName, componentConfig.default || componentConfig)
    })

    app.use(router).use(createPinia()).use(vuetify).mount('#app')
  }
})
