import { computed, ref } from 'vue'

const useCounter = () => {
  const num = ref(0)

  const disabled = computed(() => num.value < 1)

  const reset = () => {
    num.value = 0
  }

  const increase = () => {
    num.value++
  }

  const decrease = () => {
    if (num.value > 0) num.value--
  }

  return { num, disabled, reset, increase, decrease }
}

export default useCounter
