import { ref } from 'vue'
import {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  FirebaseError
} from '@/plugins/firebase'
import { supabase } from '@/plugins/supabase'
import { FormAuth } from '@/@types'
import { useNotification, useUtils } from '@/composables'
import useAuthErrors from './useAuthErrors'

const isPending = ref(false)
const { notification, setNotification, resetNotification } = useNotification()
const { authError } = useAuthErrors()

const signup = async (formData: FormAuth) => {
  const { email, password, displayName } = formData
  const { delay } = useUtils()
  isPending.value = true
  resetNotification()
  await delay()
  try {
    await delay()
    const res = await createUserWithEmailAndPassword(auth, email, password)
    if (!res)
      throw new FirebaseError('auth/default-error', 'Erro ao tentar cadastrar')
    await updateProfile(res.user, { displayName })
    console.log(res.user)
    isPending.value = false
    setNotification(false, 'success', 'Cadastrado com sucesso. Aguarde', '')
    await delay()
    resetNotification()
    return res.user
  } catch (err) {
    //const e = err as FirebaseError
    isPending.value = false
    // error.value = true
    //setNotification(true, 'error', authError(e.code), '')
    console.log(err)
  }
}

const supabaseSignup = async (formData: FormAuth) => {
  const { email, password, displayName } = formData
  const { delay } = useUtils()
  isPending.value = true
  resetNotification()
  await delay()
  try {
    const res = await supabase.auth.signUp(
      { email, password },
      { data: { displayName } }
    )
    console.log(res.user)
    isPending.value = false
    setNotification(false, 'success', 'Cadastrado com sucesso. Aguarde', '')
    await delay()
    resetNotification()
    return res.user
  } catch (err) {
    const e = err as FirebaseError
    isPending.value = false
    setNotification(true, 'error', authError(e.code), '')
    console.log(e)
  }
}

const useSignup = () => {
  return { signup, isPending, notification, supabaseSignup }
}
export default useSignup
