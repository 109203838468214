import { ref } from 'vue'
import { FormResetPassword } from '@/@types'
import { auth, sendPasswordResetEmail, FirebaseError } from '@/plugins/firebase'
import useAuthErrors from './useAuthErrors'
import { useNotification, useUtils } from '@/composables'

const isPending = ref(false)
const { notification, setNotification, resetNotification } = useNotification()
const { authError } = useAuthErrors()

const resetPassword = async (formData: FormResetPassword) => {
  const { email } = formData
  const { delay } = useUtils()
  isPending.value = true
  resetNotification()
  await delay()
  try {
    await sendPasswordResetEmail(auth, email)
    isPending.value = false
    setNotification(
      false,
      'success',
      'Senha redefinida com sucesso. Acesse seu email com o link.',
      ''
    )
    await delay()
    resetNotification()
  } catch (err) {
    const e = err as FirebaseError
    isPending.value = false
    setNotification(true, 'error', authError(e.code), '')
    console.log(e)
  }
}

const useResetPassword = () => {
  return { resetPassword, isPending, notification }
}
export default useResetPassword
