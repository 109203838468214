import { ref } from 'vue'
import {
  auth,
  signInWithEmailAndPassword,
  FirebaseError,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider
} from '@/plugins/firebase'
import { FormLogin } from '@/@types'
import useAuthErrors from './useAuthErrors'
import { useNotification, useUtils } from '@/composables'

const isPending = ref(false)
const { notification, setNotification, resetNotification } = useNotification()
const { authError } = useAuthErrors()

const login = async (formData: FormLogin) => {
  const { email, password } = formData
  const { delay } = useUtils()
  isPending.value = true
  resetNotification()
  try {
    await delay()
    const res = await signInWithEmailAndPassword(auth, email, password)
    if (!res)
      throw new FirebaseError('auth/default-error', 'Não foi possível logar-se')
    console.log(res.user)
    isPending.value = false
    setNotification(false, 'success', 'Logado com sucesso. Aguarde', '')
    await delay()
    resetNotification()
    return res.user
  } catch (err) {
    const e = err as FirebaseError
    isPending.value = false
    setNotification(true, 'error', authError(e.code), '')
    console.log(e)
  }
}

const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  const { delay } = useUtils()
  isPending.value = true
  resetNotification()

  try {
    const res = await signInWithPopup(auth, provider)
    if (!res)
      throw new FirebaseError(
        'auth/default-error',
        'Não foi possível locar como Google no momento'
      )
    isPending.value = false
    setNotification(false, 'success', 'Logado com sucesso. Aguarde', '')
    await delay()
    resetNotification()
  } catch (err) {
    const e = err as FirebaseError
    isPending.value = false
    setNotification(true, 'error', authError(e.code), '')
    console.log(e)
  }
}

const loginWithFacebook = async () => {
  const provider = new FacebookAuthProvider()
  const { delay } = useUtils()
  isPending.value = true
  resetNotification()

  try {
    const res = await signInWithPopup(auth, provider)
    if (!res)
      throw new FirebaseError(
        'auth/default-error',
        'Não foi possível locar como Facebook no momento'
      )
    isPending.value = false
    setNotification(false, 'success', 'Logado com sucesso. Aguarde', '')
    await delay()
    resetNotification()
  } catch (err) {
    const e = err as FirebaseError
    isPending.value = false
    setNotification(true, 'error', authError(e.code), '')
    console.log(e)
  }
}

const useLogin = () => {
  return { isPending, notification, login, loginWithGoogle, loginWithFacebook }
}

export default useLogin
