export type NotificationType = 'error' | 'success' | 'warning' | null

export interface Notification {
  error: boolean | null
  type: NotificationType
  message: string
  code: string
}

import { ref } from 'vue'

const useNotification = (): {
  notification: typeof notification
  setNotification: typeof setNotification
  resetNotification: typeof resetNotification
} => {
  const notification = ref<Notification>({
    error: null,
    type: null,
    message: '',
    code: ''
  })

  const setNotification = (
    error: boolean | null,
    type: NotificationType,
    message: string,
    code: string
  ) => {
    notification.value = {
      error,
      type,
      message,
      code
    }
  }

  const resetNotification = () => {
    notification.value = {
      error: null,
      type: null,
      message: '',
      code: ''
    }
  }

  return { notification, setNotification, resetNotification }
}

export default useNotification
