import { loadingStore } from '@/store'

const overlayLoading = (data: boolean) => {
  const store = loadingStore()
  store.setOverlayLoading(data)
}
const useLoading = () => {
  return { overlayLoading }
}
export default useLoading
