import { defineStore } from 'pinia'
import { RecordedData } from '../@types/tracker'
import { useUtils } from '@/composables'
import useTracker from '../composables/useTracker'

export const useStore = defineStore('tracker', {
  state: () => ({
    tracker: [] as RecordedData[]
  }),
  actions: {
    addTracker(data: RecordedData) {
      const { uuid } = useUtils()
      this.tracker.push({ uid: uuid(), ...data })
    }
  },
  getters: {
    formattedTrackers: (state) => {
      const { numberForTime } = useTracker()
      const data = state.tracker.map((item) => {
        return {
          uid: item.uid,
          name: item.name.toUpperCase(),
          elapsedTime: numberForTime(
            item.laps?.reduce((a, b) => a + b, 0) || 0
          ),
          laps: item.laps?.map((lap) => numberForTime(lap))
        }
      })
      return data
    }
  }
})
