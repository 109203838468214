import { ref } from 'vue'
import { auth, signOut, FirebaseError } from '@/plugins/firebase'
import { useNotification, useUtils } from '@/composables'
import useAuthErrors from './useAuthErrors'

const isPending = ref(false)
const { delay } = useUtils()
const { notification, setNotification, resetNotification } = useNotification()
const { authError } = useAuthErrors()

const logout = async () => {
  isPending.value = true
  resetNotification()
  await delay()
  try {
    await signOut(auth)
    setNotification(false, 'success', 'Você foi desconectado do sistema', '')
    isPending.value = false
  } catch (err) {
    const e = err as FirebaseError
    setNotification(true, 'error', authError(e.code), '')
    isPending.value = false
  }
}

const useLogout = () => {
  return { isPending, notification, logout }
}

export default useLogout
