import {
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext
} from 'vue-router'
import { WelcomeView, ProfileView } from './views'

const validateParam = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  const validParams = ['login', 'signup', 'reset']
  const mode = to.params.mode as string
  if (validParams.includes(mode) || !mode) {
    next()
  } else {
    next({ path: '/welcome' })
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/welcome/:mode?',
    name: 'Welcome',
    component: WelcomeView,
    meta: {
      title: 'Welcome',
      requiresAuth: false,
      showInDrawer: false,
      hideNavBar: true
    },
    beforeEnter: validateParam,
    props: (route) => ({ mode: route.params.mode || 'login' })
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  }
]

export default routes
