import { createClient } from '@supabase/supabase-js'
const supabaseUrl = process.env.VUE_APP_SUPABASE_URL
const supabaseKey = process.env.VUE_APP_SUPABASE_ANON_KEY
export const supabase = createClient(
  supabaseUrl as string,
  supabaseKey as string
)
console.log('init supabase', supabase)
export default function useSupabase() {
  return { supabase }
}
