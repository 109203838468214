import { ref, computed } from 'vue'
import { useStore } from '../store/tracker'
import { ClockStatus } from '../@types/tracker'
import { useInterval } from '@vueuse/core'

const useTracker = () => {
  const clockStatus = ref<ClockStatus>('stopped')
  const name = ref(Date.now().toString())
  const laps = ref<number[]>([])

  const {
    counter: fullTime,
    pause: pauseFullTime,
    resume: resumeFullTime
  } = useInterval(1000, {
    immediate: false,
    controls: true
  })
  const {
    counter: splitTime,
    pause: pauseSplitTime,
    resume: resumeSplitTime
  } = useInterval(1000, {
    immediate: false,
    controls: true
  })

  const disabled = ref({
    start: () => clockStatus.value === 'running' || name.value === '',
    paused: () =>
      clockStatus.value === 'paused' ||
      name.value === '' ||
      clockStatus.value === 'stopped',
    stopped: () =>
      clockStatus.value === 'running' ||
      name.value === '' ||
      clockStatus.value === 'stopped'
  })

  const handleStart = () => {
    clockStatus.value = 'running'
    resumeSplitTime()
    resumeFullTime()
  }

  const handlePause = () => {
    clockStatus.value = 'paused'
    pauseFullTime()
    pauseSplitTime()
  }

  const handleStop = () => {
    handlePause()
    handleLap()
    clockStatus.value = 'stopped'
    splitTime.value = 0
    fullTime.value = 0
    name.value = ''
    laps.value = []
  }

  const handleSave = () => {
    const store = useStore()
    if (!laps.value.length) handleLap()
    store.addTracker({
      name: name.value,
      laps: laps.value
    })
    handleStop()
  }

  const handleLap = () => {
    laps.value.push(splitTime.value)
    splitTime.value = 0
  }

  const numberForTime = (data: number) => {
    return new Date(data * 1000).toISOString().substring(11, 19).toString()
  }

  const getLaps = () => {
    return laps.value.map((lap) => numberForTime(lap))
  }

  const elapsedSplitTime = computed(() => {
    return numberForTime(splitTime.value)
  })
  const elapsedFullTime = computed(() => {
    return numberForTime(fullTime.value)
  })
  return {
    name,
    laps,
    getLaps,
    clockStatus,
    elapsedSplitTime,
    elapsedFullTime,
    disabled,
    numberForTime,
    handleStart,
    handlePause,
    handleSave,
    handleStop,
    handleLap
  }
}

export default useTracker
